<template>
  <div>
    <b-row no-gutters class="mr-3" >
      <b-col
        class="u-pearl"
        :class="step >= 0 ? 'done' : ''"
      >
        <template v-if="step >= 1">
          <div
            class="u-pearl-icon"
            @click="$emit('on:step-0:selected')"
          >
            <span class="pointer pearl-icon-check fa fa-check"></span>
          </div>
        </template>
        <template v-else>
          <div class="u-pearl-icon">
            1
          </div>
        </template>
      </b-col>
      <b-col
        class="u-pearl"
        :class="step >= 1 ? 'done' : ''"
      >
        <template v-if="step >= 2">
          <div @click="$emit('on:step-1:selected')" class="u-pearl-icon">
            <span class="pointer pearl-icon-check fa fa-check"></span>
          </div>
        </template>
        <template v-else>
          <div class="u-pearl-icon">
            2
          </div>
        </template>
      </b-col>
      <b-col
        :class="step >= 2 ? 'done' : ''"
        align="right"
        class="u-pearl"
      >
        <template v-if="step >= 3">
          <div @click="onStep3Click" class="u-pearl-icon">
            <span class="pointer pearl-icon-check fa fa-check"></span>
          </div>
        </template>
        <template v-else>
          <div class="u-pearl-icon">
            3
          </div>
        </template>
      </b-col>
      <b-col
        v-if="isStep4"
        :class="step >= 3 ? 'done' : ''"
        align="right"
        class="u-pearl"
      >
        <template v-if="step >= 3">
          <div @click="$emit('on:step-4:selected')" class="u-pearl-icon">
            <span class="pointer pearl-icon-check fa fa-check"></span>
          </div>
        </template>
        <template v-else>
          <div class="u-pearl-icon">
            4
          </div>
        </template>
      </b-col>
    </b-row>
    <b-row class="mt-1" no-gutters align="right">
      <b-col class="margin-col-first" align="right">
        <div
          :class="step >= 0 ? 'subtitle-step-active' : 'subtitle-step-inactive'"
          @click="$emit('on:step-0:selected')"
        >
          <label class="font-text-title pointer">{{ $t(step0Label) }}</label>
        </div>
      </b-col>
      <b-col align="right">
        <div
          :style="step2Margins"
          :class="step >= 1 ? 'subtitle-step-active' : 'subtitle-step-inactive'"
          @click="onStep1Click()"
        >
          <label
            :class="step >= 1 ? 'pointer font-text-title' : 'font-text-title'"
          >
            {{ $t(step1Label) }}
          </label>
        </div>
      </b-col>
      <b-col
        align="right"
      >
        <div
          :style="step3Margins"
          :class="step >= 2 ? 'subtitle-step-active' : 'subtitle-step-inactive'"
          @click="onStep2Click()"
        >
          <label
            :class="step >= 2 ? 'pointer font-text-title' : 'font-text-title'"
          >
            {{ $t(step2Label) }}
          </label>
        </div>
      </b-col>
      <b-col v-if="isStep4" align="right">
        <div
          :class="step >= 3 ? 'subtitle-step-active' : 'subtitle-step-inactive'"
          @click="onStep3Click()"
        >
          <label
            :class="step >= 3 ? 'pointer font-text-title' : 'font-text-title'"
          >
            {{ $t(step3Label) }}
          </label>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    },
    maxSteps: {
      type: Number,
      default: 3
    },
    isStep4: {
      type: Boolean,
      default: true,
    },
    step1Margin: {
      type: String,
      default : ''
    },
    step2Margin: {
      type: String,
      default : ''
    },
    step3Margin: {
      type: String,
      default : ''
    },
    step0Label: {
      type: String,
      default : 'admin.clubs.import.steps.step0.title'
    },
    step1Label: {
      type: String,
      default : 'admin.clubs.import.steps.step1.title'
    },
    step2Label: {
      type: String,
      default : 'admin.clubs.import.steps.step2.title'
    },
    step3Label: {
      type: String,
      default : 'admin.clubs.import.steps.step3.title'
    }
  },
  computed: {
    step1Margins () {
      return 'margin-right:' + this.step1Margin + 'px';
    },
    step2Margins () {
      return 'margin-right:' + this.step2Margin + 'px';
    },
    step3Margins () {
      return 'margin-right:' + this.step3Margin + 'px';
    }
  },
  methods: {
    onStep2Click () {
      if (this.step >= 2) {
        console.log('here')
        this.$emit('on:step-2:selected');
      }
    },
    onStep3Click () {
      if (this.step >= 3) {
        console.log('here')
        this.$emit('on:step-3:selected');
      }
    },
    onStep1Click () {
      if (this.step >= 1) {
        this.$emit('on:step-1:selected');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.u-pearl.done .u-pearl-icon, .u-pearl.done .u-pearl-number {
  color: #FFF;
  background-color: #FF0101;
  border-color: #FF0101;
}

.u-pearl.start .u-pearl-icon, .u-pearl.start .u-pearl-number {
  color: #4D4F5C;
  background-color: #FF0101;
  border-color: #FF0101;
}

.u-pearl.pending .u-pearl-icon, .u-pearl.pending .u-pearl-number {
  color: #4D4F5C;
  background-color: #EDF0F3;
  border-color: #EDF0F3;
}

.u-pearl.done:before {
  background-color: #FF0101 !important;
  cursor: default;
}

.u-pearl.done:after {
  cursor: default;
  background-color: #EDF0F3 !important;
}

.u-pearl.start:after, .u-pearl.start:before {
  background-color: #EDF0F3 !important;
}

.u-pearl.start:before {
  background-color: #FF0101 !important;
}

.u-pearl.start-done:before {
  background-color: #FF0101 !important;
}
/deep/ .font-text-title {
  font-size: 13px;
  font-weight: 500;
}

.subtitle-step-active {
  text-align: right;
  color: #FF0101;
  letter-spacing: 0px;
  opacity: 1;
}

.subtitle-step-inactive {
  text-align: right;
  letter-spacing: 0px;
  color: #52575D;
  opacity: 1;
}

.u-pearl {
  position: relative;
  padding: 0;
  margin: 0;
  text-align: right;
}

.u-pearl:after {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 0%;
  right: 0;
  height: 90px;
  content: "";
  background-color: #e8f4fe;
}

.u-pearl:before {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 100%;
  height: 4px;
  content: "";
  background-color: #e8f4fe;
}

.u-pearl-icon {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 32px;
  color: #4D4F5C;
  font-size: 11px;
  text-align: center;
  background: #EDF0F3;
  border: 2px solid #EDF0F3;
  border-radius: 50%;
}

.pearl-icon-check {
  font-size: 16px;margin-top:8px
}

</style>
